:root {
    --person-width: 150px;
}

.fila {
    display: flex;
    position: relative;
    overflow-x: auto;
    border: 1px solid #ccc;
    border-right: 0px;
    height: 100%;
}

.day {
    flex: 1;
    min-width: 0;
    border-right: 1px solid #ccc;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10px;
    flex-direction: column;
}

.highlight {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    height: 50%;
    background-color: lightblue;
    z-index: 1;
    /* pointer-events: none; */
    border-radius: 15px;
    
}

.person-name {
    flex-basis: var(--person-width);
    min-width: 0;
    /* text-align: center; */
    /* padding: 15px 10px; */
    border-right: 1px solid #ccc;
    /* background-color: lightgray; */
}