.container-movil-pdf-header {
	height: 60px;
	max-height: 60px;
	display: flex;
	align-items: center;
	padding-left: 15px;
	color: #1c74b3;
	font-size: 15px;
	border-bottom: 1px solid #cccccc;
	font-weight: bold;
	box-shadow: 0px 2px 10px #ccc;
	width: 100%;
	z-index: 100;
}