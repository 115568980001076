@import "~antd/dist/antd.less";

@form-control-color: #e6f7ff;
@form-control-focus-color: #40a9ff;
@form-control-color-error-background: #f9d9d4;
@form-control-color-error-border: #ffbb96;

/* Override Css */
* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

#root {
	height: 100%;
}

body {
	background: #f4f4f4;
}

.ant-card-bordered {
	border: 1px solid #ddd;
}

.ant-card-head {
	border-bottom: 1px solid #ddd;
}

/* Login CSS */

.container-login__formulario {
	display: flex;
	justify-content: center;
	align-items: center;
	padding-left: 15px;
	padding-right: 15px;

	flex: 1 1 40%;
}

.container-login__formulario>.login {
	width: 100%;
	/* padding: 15px; */
	padding-left: 15px;
	padding-right: 15px;
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	padding-right: 5px;
	flex: 1;
}

.container-login__formulario>.login>.logotipo {
	text-align: center;
	align-items: center;
	display: flex;
	flex: 1;
	align-self: center;
	/* margin-bottom: 12px; */
}

.container-login__formulario>.login>.logotipo>.imagen-derecha {
	display: block;
	margin: 0 auto;
	margin-bottom: 60px;
	background-repeat: no-repeat;
	//background-size: cover;
	//background-position: center center;
	//max-width: 100%;
	//max-height: 120px;
	width: 285px;
	height: 90px;
	flex-wrap: wrap;
	flex: 1;
	align-content: center;
}

//283 * 85

.container-login__formulario>.login>.texto {
	text-align: center;
	margin-bottom: 24px;
	flex-wrap: wrap;
	flex: 1;
}

/* Plantilla Administrador CSS */
.layout {
	background: #f4f4f4;
}

.layout-header {
	display: flex;
	position: fixed;
	width: 100%;
	top: 0;
	z-index: 100;
}

.layout-header__logotipo {
	padding-right: 15px;
}

.layout-header__logotipo-imagen {
	height: 32px;
	padding-right: 10px;
}

.layout-header__menu {
	flex: 1 1 0%;
	min-width: 0;
}

.layout-header__menu-perfil {
	cursor: pointer;
}

.layout-content {
	// margin-top: 32px;
	padding: 32px 50px;
	// padding-bottom: 30px;
}

.layout-footer {
	text-align: center;
}

/* Formulario Crear */

.ant-card .ant-card-body .container-crear-titulo {
	text-align: center;
	font-size: 32px;
	line-height: 1.8;
}

.ant-card .ant-card-body .container-crear-descripcion {
	color: rgba(0, 0, 0, 0.45);
	text-align: center;
	font-size: 14px;
	line-height: 1.8;
}

/* Input */
// .ant-input {
//   border-radius: 5px;
// }

//Table ant design personalizacion de la tabla
// .ant-table {
// 	color: #7f7f7f;
// }

// .ant-table-thead>tr>th {
// 	color: #1678c1;
// 	background: #ffffff;
// 	border-bottom: 1px solid #1678c1;
// }

//custom color elementos
.custom-color-element {
	color: #1678c1 !important;
}

.ant-menu {
	color: #1678c1;
	font-weight: 500;
}

.ant-btn-primary {
	background: #1678c1;
	border-color: #1678c1;
}

// .ant-menu-horizontal > .ant-menu-item-selected,
// .ant-menu-horizontal > .ant-menu-submenu-selected {
// 	color: #1678c1;
// 	border-bottom: 3px solid #1678c1;
// }

.texto-menu-perfil {
	font-weight: 500;
}

.card-comunicaciones {
	box-shadow: 10px 10px 5px -4px rgba(0, 0, 0, 0.3);
}

.documento-no-seleccionado {
	color: #868789;
}

.documento-seleccionado {
	color: #48a8fc;
}

.custom-th-tr-border {
	border-bottom: 1px solid #ffffff !important;
}

.login-form {
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	flex: 1;
	padding-right: 10px;
}

.site-form-item-icon {
	flex: 1;
	min-width: 0px;
	display: block;
}

/* estilos responsive design */

.container {
	height: 100vh;
	min-height: 100vh;
	display: flex;
	flex-flow: wrap;
	width: 100%;
	background-color: white;
	align-items: center;
}

.container-login {
	height: 100vh;
	min-height: 100vh;
	display: flex;
	flex-flow: wrap;
	width: 100%;
	background-color: white;
}

.container-login--wrapper {
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 100%;
	padding: 0 20px;
}

.container-login--wrapper__logotipo {
	margin: 0 auto;
}

.container-login--wrapper__titulo {
	margin: 0 auto;
	font-size: 24px;
	font-weight: bold;
	color: #4c4d4e;
	padding-bottom: 30px;
	padding-top: 30px;
}

.container-login--fondo {
	display: none;
}

.container-recuperar-clave-wrapper {
	display: flex;
	flex-direction: column;
	// justify-content: center;
	width: 100%;
	padding: 0 20px;
	height: 80%;
	justify-content: center;
}

.container-recuperar-clave-logotipo {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 20%;
}

.container-recuperar-clave-titulo {
	display: block;
	text-align: center;
	font-size: 20px;
	font-weight: bold;
	color: #4c4d4e;
	margin-top: 12px;
	margin-bottom: 18px;
}

.container-recuperar-clave-retornar {
	height: 10%;
	display: flex;
	align-items: center;
}

.container-recuperar-clave-body {
	// display: inherit;
	// flex-direction: inherit;
	// justify-content: inherit;
	height: 90%;
}

.container-movil {
	display: flex;
	// flex-wrap: wrap;
	// flex-direction: row;
	flex-flow: row wrap;
	background-color: white;
	min-height: 100%;
	height: 100%;

	// height: 100vh;
	// min-height: 100vh;
	// width: 100%;
	// max-width: 100vw;
}

.container-movil-body {
	width: 100%;
	height: 90%;
}

.bandeja-entrada__container-documentos {
	overflow: auto;
	// display: flex;
	// flex-direction: column;
	height: calc(100% - 60px);
	padding-top: 10px;
	padding-bottom: 10px;
	// overflow: auto;
}

.bandeja-entrada__item-documento-pendiente {
	display: flex;
	min-height: 20px;
	border: 1px solid #cccccc;
	border-left: 5px solid #f57d76;
	border-radius: 5px;
	margin: 10px 15px;
	padding-top: 8px;
	padding-bottom: 10px;
	/*box-shadow: 0px 0.5px 0px #949191;*/
}

.bandeja-entrada__item-documento-pendiente .imagen {
	display: flex;
	justify-content: center;
	align-items: center;
	padding-left: 15px;
	padding-right: 15px;
	font-size: 32px;
	color: #f1746d;
}

.bandeja-entrada__item-documento-pendiente .informacion {
	width: 100%;
}

.bandeja-entrada__item-documento-pendiente .acciones {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 40%;
	// color: #e7a703;
}

.item-documento__descripcion {
	display: block;
}

.item-documento__tipo {
	display: block;
}

.item-documento__estado-pendiente {
	display: block;
	color: #f1746d;
}

/* ingenioti: tab-panel */
.bandeja-entrada__tab {
	display: flex;
	flex-direction: column;
	min-height: 100%;
}

.bandeja-entrada__tab-header {
	display: flex;
	width: 100%;
	height: 40px;
}

.bandeja-entrada__tab-header-item {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	border-bottom: 1px solid #cccccc;
}

.bandeja-entrada__tab-header-item.activo {
	color: #1c74b3;
	font-weight: 500;
	border-bottom: 2px solid #1c74b3;
}

.bandeja-entrada__tab-body {
	min-height: calc(100% - 40px);
	padding-top: 5px;
}

.bandeja-entrada__tab-body-info {
	display: none;
}

.bandeja-entrada__tab-body-info.mostrar {
	display: block;
	min-height: 100%;
	max-height: 100%;
	overflow: auto;
}

/* CSS Movil */
// .container-movil-pdf-body {
// 	height: calc(100% - 60px);
// 	background: rgba(var(--sk_foreground_min, 29, 28, 29), 0.04);
// 	display: flex;
// 	justify-content: center;
// 	align-items: center;
// 	width: 100%;
// 	border: 1px solid red;

// 	.loading {
// 		font-size: 40px;
// 		color: #2b81de;
// 		display: flex;
// 		align-items: center;
// 		justify-content: center;
// 		background: #f6f6f6;
// 		height: calc(100% - 64px);
// 		width: 100%;
// 		position: absolute;
// 		z-index: 1000;
// 	}

// 	.react-pdf {
// 		&__Document {
// 			display: flex;
// 			flex-direction: column;
// 			align-items: center;
// 			height: 100%;
// 			width: 100%;
// 			overflow: auto;

// 		}

// 		&__Page {
// 			max-width: calc(~"100% - 2em");
// 			// max-width: 100%;
// 			margin-top: 1em;

// 			canvas {
// 				max-width: 100%;
// 				height: auto !important;
// 			}
// 		}
// 	}
// }

.container-movil-pdf-body {
	height: calc(100% - 120px);
	display: flex;
	align-items: center;
	justify-content: center;
	max-width: 100%;
	width: 100%;
	overflow: hidden;
}

.container-movil-pdf-body .react-pdf__Document {
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.new-container-movil {
	position: relative;
	background-color: white;
	min-height: 100%;
	height: 100%;
	//height: calc(100vh - 60px);
}

.new-container-movil-body {
	height: calc(100% - 120px);
	overflow: auto;
}

.contianer-loading-movil-body {
	display: flex;
	align-items: center;
	justify-content: center;
	background: #f6f6f6;
	height: calc(100% - 64px);
	width: 100%;
	position: absolute;
	z-index: 1000;
}

.container-body-sin-menu-movil {
	display: flex;
	align-items: center;
	justify-content: center;
	height: calc(100% - 50px);
	width: 100%;
	//overflow-y: scroll;
	//position: absolute;
}

.new-container-movil-pdf-loading {
	font-size: 32px;
	color: #2b81de;
	background: #f6f6f6;
	position: absolute;
	z-index: 1001;
}

.new-container-movil-pdf-body {
	background: rgba(var(--sk_foreground_min, 29, 28, 29), 0.04);
}

.new-container-movil-pdf-body .react-pdf__Document {
	height: 100%;
}

.new-container-movil-pdf-body .react-pdf__Page {
	max-width: calc(~"100% - 2em");
	margin-top: 1em;
}

/* Formulario - Móvil */

.formulario-container-movil-body {
	height: calc(100% - 120px - 32px);
	overflow: auto;
}

.formulario-container-movil-body-pdf {
	background: rgba(var(--sk_foreground_min, 29, 28, 29), 0.04);
	height: 100%;
	// display: flex;
	// flex-direction: column;
	// align-items: center;
	// justify-content: center;
}

.formulario-container-movil-body-pdf .react-pdf__Document {
	height: 100%;
	// display: flex;
	// flex-direction: column;
	// align-items: center;
	// justify-content: center;
}

.formulario-container-movil-body-pdf .react-pdf__Page {
	// height: 100%;
	//max-width: calc(~"100% - 2em");
	// border: 2px solid green;
	// margin-bottom: 4px;
}

.formulario-container-movil-body-pdf .react-pdf__Page canvas {
	// border: 2px solid red;
	// width: 100%;
	//max-width: calc(~"100% - 2em");
	// border: 2px solid green;
	// margin-top: 2px;
	// margin-bottom: 4px;
	border-bottom: 1px solid #cccccc;
}

.container-movil-pdf-footer {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	border-top: 1px solid #ccc;
	padding: 0px 10px;
	height: 60px;
	max-height: 60px;
	width: 100%;
	max-width: 100%;
}

.App-zoom {
	display: inline-block;
	width: 100%;
	max-width: 640px;
}

// .react-pdf__Page {
// 	max-width: 100%;
// }

// .react-pdf__Document {
// 	overflow: auto;
// }

.ant-tag {
	margin-right: 0px;
}

.documentos__item-documento {
	display: flex;
}

.documentos__item-documento .imagen {
	display: flex;
	justify-items: center;
	align-items: center;
	font-size: 32px;
	padding-right: 15px;
}

.documentos__item-documento .imagen.pendiente {
	color: #f1746d;
}

.visor-pdf-header {
	min-height: 64px;
	border-bottom: 1px solid rgba(var(--sk_foreground_low, 29, 28, 29), 0.13);
	box-shadow: inset 0 1px 0 rgba(0, 0, 0, 0.2);
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 12px 16px;
	min-width: 0;

	.informacion {
		display: flex;
		align-items: center;

		.icono {
			font-size: 30px;
			color: #2b81de;
		}

		.texto {
			display: flex;
			flex-direction: column;
			margin-left: 5px;
			font-size: 12px;

			.periodo {
				color: black;
				font-weight: bold;
			}

			.documento {
				color: rgba(var(--sk_foreground_max, 29, 28, 29), 0.7);
				font-weight: bold;
			}
		}
	}

	.acciones {
		display: flex;

		.firmar {
			padding: 0 50px;
			margin-left: 15px;
			margin-right: 10px;
		}

		.consultar-rrhh {
			margin-left: 15px;
		}

		.cerrar {
			padding-left: 10px;
			padding-right: 10px;
		}
	}
}

.visor-pdf-body {
	height: calc(100% - 64px);
	background: rgba(var(--sk_foreground_min, 29, 28, 29), 0.04);
	// border: 1px solid green;
	display: flex;
	justify-content: center;
	align-items: center;

	.container-loading {
		display: flex;
		align-items: center;
		justify-content: center;
		background: #f6f6f6;
		height: calc(100% - 64px);
		width: 100%;
		position: absolute;
		z-index: 1000;

		.loading {
			font-size: 40px;
			color: #2b81de;
			background: #f6f6f6;
			position: absolute;
			z-index: 1001;
		}
	}

	.react-pdf {
		&__Document {
			display: flex;
			flex-direction: column;
			align-items: center;
			height: 100%;
			width: 100%;
			overflow: auto;
		}

		&__Page {
			max-width: calc(~"100% - 2em");
			// box-shadow: 0 	0 8px rgba(0, 0, 0, 0.5);
			margin-top: 1em;

			canvas {
				max-width: 100%;
				height: auto !important;
			}
		}
	}
}

.visor-formulario-tool-movil {
	background: #fafafa;
	border: 1px solid #d9d9d9;
	text-align: center;
	min-height: 32px;
}

.visor-pdf-header-separador {
	width: 1px;
	display: inline-block;
	height: 32px;
	background: rgba(var(--sk_foreground_low, 29, 28, 29), 0.13);
	margin: 0 8px;
}

.visor-pdf-header-botones {
	margin-left: 10px;
}

.milegajo-ant-modal {
	.ant-modal-body {
		padding: 0px;
	}

	.ant-modal-content {
		height: 100%;
	}
}

/* item documento - inicio */

.bandeja-entrada__item-documento.loading {
	border-left: 5px solid #ccc;
}

.bandeja-entrada__item-documento .imagen.loading {
	color: #ccc;
}

.item-documento__estado.loading {
	color: #ccc;
}

.bandeja-entrada__item-documento.pendiente-aprobacion {
	border-left: 5px solid #1890ff;
}

.bandeja-entrada__item-documento .imagen.pendiente-aprobacion {
	color: #1890ff;
}

.item-documento__estado.pendiente-aprobacion {
	color: #1890ff;
}

.bandeja-entrada__item-documento.pendiente {
	border-left: 5px solid #faad14;
}

.bandeja-entrada__item-documento .imagen.pendiente {
	color: #faad14;
}

.item-documento__estado.pendiente {
	color: #faad14;
}

.bandeja-entrada__item-documento.visualizado {
	border-left: 5px solid #8abf60;
}

.bandeja-entrada__item-documento .imagen.visualizado {
	color: #8abf60;
}

.item-documento__estado.visualizado {
	color: #3bb937;
}

.bandeja-entrada__item-documento.rechazado {
	border-left: 5px solid #f57d76;
}

.bandeja-entrada__item-documento .imagen.rechazado {
	color: #f57d76;
}

.item-documento__estado.rechazado {
	color: #f57d76;
}

/* bloqueado */
.bandeja-entrada__item-documento.bloqueado {
	border-left: 5px solid #979393;
}

.bandeja-entrada__item-documento .imagen.bloqueado {
	color: #979393;
}

.item-documento__estado.bloqueado {
	color: #979393;
}

.bandeja-entrada__item-documento {
	display: flex;
	min-height: 20px;
	border: 1px solid #cccccc;
	border-radius: 5px;
	margin: 10px 15px;
	padding-top: 8px;
	padding-bottom: 10px;
	// border-left: 5px solid #8abf60;
}

.bandeja-entrada__item-documento .imagen {
	display: flex;
	justify-content: center;
	align-items: center;
	padding-left: 15px;
	padding-right: 15px;
	font-size: 32px;
	// color: #8abf60;
}

.bandeja-entrada__item-documento .informacion {
	width: 100%;
}

.bandeja-entrada__item-documento .acciones {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 40%;
	// color: #3bb937;
}

.item-documento__estado-visualizado {
	display: block;
	// color: #3bb937;
}

.milegajo-primary-color {
	color: #2b81de;
}

/* item documento - fin */

.verificar-colaborador-informacion {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 0px 60px;
}

@media (min-width: 420px) {
	.container-recuperar-clave-wrapper {
		max-width: 420px;
		margin: 0 auto;
	}
}

@media (min-width: 600px) {
	.container-login--wrapper {
		max-width: 600px;
		margin: 0 auto;
	}
}

@media (min-width: 800px) {
	.container-login--fondo {
		width: 65%;
		display: flex;
		background-size: 100% 100%;
		background-position: center;
	}

	.container-login--wrapper {
		width: 35%;
	}
}

/* formularios */

.visor-formulario-loading {
	display: flex;
	align-items: center;
	justify-content: center;
	background: #f6f6f6;
	height: calc(100% - 64px);
	width: 100%;
	position: absolute;
	z-index: 1000;

	.loading {
		font-size: 40px;
		color: #2b81de;
		background: #f6f6f6;
		position: absolute;
		z-index: 1001;
	}
}

.visor-formulario-tool {
	background: #eee;
	border: 1px solid #d9d9d9;
	text-align: center;
	min-height: 32px;
}

.visor-formulario-body {
	height: calc(100% - 32px);
	background: rgba(var(--sk_foreground_min, 29, 28, 29), 0.04);
	display: flex;
	justify-content: center;
	align-items: center;

	.container-loading {
		display: flex;
		align-items: center;
		justify-content: center;
		background: #f6f6f6;
		height: calc(100% - 64px);
		width: 100%;
		position: absolute;
		z-index: 1000;

		.loading {
			font-size: 40px;
			color: #2b81de;
			background: #f6f6f6;
			position: absolute;
			z-index: 1001;
		}
	}

	.react-pdf {
		&__Document {
			display: flex;
			flex-direction: column;
			align-items: center;
			height: 100%;
			width: 100%;
			overflow: auto;
		}

		&__Page {
			canvas {
				max-width: 100%;
				height: auto !important;
			}
		}
	}
}

.visor-formulario-generico-body {
	background: rgba(var(--sk_foreground_min, 29, 28, 29), 0.04);
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	min-width: 100%;

	.react-pdf {
		&__Document {
			// display: flex;
			flex-direction: column;
			align-items: center;
			height: 100%;
			max-width: 100%;
			min-width: 100%;
			overflow: auto;
		}

		&__Page {
			canvas {
				width: 100%;
				height: auto !important;
			}
		}
	}
}

.visor-formulario-generico-body-center {
	background: rgba(var(--sk_foreground_min, 29, 28, 29), 0.04);
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	min-width: 100%;

	.react-pdf {
		&__Document {
			display: flex;
			flex-direction: column;
			align-items: center;
			height: 100%;
			max-width: 100%;
			min-width: 100%;
			overflow: auto;
		}

		&__Page {
			canvas {
				width: 100%;
				height: auto !important;
			}
		}
	}
}

.visor-formulario-generico-body-movil {
	background: rgba(var(--sk_foreground_min, 29, 28, 29), 0.04);
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	min-width: 100%;

	.react-pdf {
		&__Document {
			// display: flex;
			flex-direction: column;
			align-items: center;
			height: 100%;
			max-width: 100%;
			min-width: 100%;
			overflow: auto;
		}

		&__Page {
			canvas {
				width: 100%;
				height: auto !important;
			}
		}
	}
}

.formulario-control-container {
	position: absolute;
	background: @form-control-color;
}

.formulario-control-container-error {
	position: absolute;
	background: @form-control-color-error-background;
}

.formulario-file {
	position: absolute;
	background: @form-control-color;
	// padding-left: 3px;
}

.formulario-file-container {
	position: absolute;
	border: 1.5px dashed @form-control-focus-color;
	display: flex;
	justify-content: center;
	cursor: pointer;
	padding: 0;
}

.formulario-input {
	position: absolute;
	background: @form-control-color;
	padding: 0px;
}

.formulario-input:focus {
	border: 1.5px solid @form-control-focus-color;
	border-right-width: 1.5px !important;
}

.formulario-input-error {
	position: absolute;
	background: @form-control-color-error-background;
	padding-left: 3px;
}

.formulario-input-error:hover {
	border: 1px solid @form-control-color-error-border;
}

.formulario-input-error:focus {
	border: 1.5px solid @form-control-color-error-border;
	border-right-width: 1.5px !important;
	box-shadow: none;
}

.formulario-list {
	position: absolute;
	background: @form-control-color;
	//padding-left: 3px;
	text-align: center;
	font-family: revert;
	font-weight: bold;
}

.formulario-list:focus {
	border: 1.5px solid @form-control-focus-color;
	border-right-width: 1.5px !important;
}

.formulario-list-error {
	position: absolute;
	background: @form-control-color-error-background;
	padding-left: 3px;
}

.formulario-list-error:hover {
	border: 1px solid @form-control-color-error-border;
}

.formulario-list-error:focus {
	border: 1.5px solid @form-control-color-error-border;
	border-right-width: 1.5px !important;
	box-shadow: none;
}

.formulario-checkbox {
	position: absolute;
	width: 100%;
	height: 100%;
}

.formulario-radio-button {
	position: absolute;
	width: 100%;
	height: 100%;
}

.formulario-hidden {
	position: absolute;
	top: 0;
	left: 0;
}

.formulario-signature-container {
	position: absolute;
	//border: 1.5px dashed @form-control-focus-color;
	display: flex;
	justify-content: center;
	cursor: pointer;
	padding: 0;
}

.formulario-signature-button {
	position: absolute;
	border: 1.5px dashed @form-control-focus-color;
	padding: 0;
}

.formulario-signature-button-error {
	position: absolute;
	border: 1.5px dashed @form-control-color-error-border;
	background: @form-control-color-error-background;
	padding: 0;
}

.formulario-file-error {
	position: absolute;
	border: 1.5px dashed @form-control-color-error-border;
	background: @form-control-color-error-background;
	padding: 0;
}

.formulario-label {
	position: absolute;
	// background: @form-control-color;
	padding-left: 3px;
	text-align: justify;
}

.tabla_resultado-evaluacion {
	background-color: #F6F4E9;
}

@media(max-width: 576px) {
	.ant-picker-panels {
		flex-direction: column;
	}
}

.convocatorias {
	// height: 100vh;
	// min-height: 100vh;
	// display: flex;
	// flex-flow: wrap;
	width: 100%;
	background-color: white;
	align-items: center;
	// margin: "30px 25px 10px 25px";
}

.convocatorias-container-wrapper {
	padding: 80px 30%;
}

@media (min-width: 800px) {
	.convocatorias-container-wrapper {
		padding: 80px 10%;
		// max-width: 420px;
		// padding: 0 80px;
		// margin: 0 auto;
		// border-style: hidden;
	}
}

.formulario-postulante-titulo {
	display: block;
	text-align: center;
	font-size: 20px;
	font-weight: bold;
	color: #4c4d4e;
	margin-top: 8px;
	margin-bottom: 18px;
}

.bandeja-entrada__item-documento.cerrado {
	border-left: 5px solid #B6275B;
}

.bandeja-entrada__item-documento .imagen.cerrado {
	color: #B6275B;
}

.item-documento__estado.cerrado {
	color: #B6275B;
}

.bandeja-entrada__item-documento.cancelado {
	border-left: 5px solid #BCB9BA;
}

.bandeja-entrada__item-documento .imagen.cancelado {
	color: #BCB9BA;
}

.item-documento__estado.cancelado {
	color: #494647;
}

/* Estilos para el scrollbar del div */
div.scrollbar-container::-webkit-scrollbar {
	width: 10px;
}

/* Estilos para el pulgar del scrollbar del div */
div.scrollbar-container::-webkit-scrollbar-thumb {
	background-color: #bbbbbb;
	border-radius: 5px;
}

/* Estilos al pasar el mouse sobre el pulgar del scrollbar del div */
div.scrollbar-container::-webkit-scrollbar-thumb:hover {
	background-color: #aaaaaa;
}

div.scrollbar-container::-webkit-scrollbar-track {
	background-color: #e2e1e1;
	/* Color de fondo del scrollbar */
	border-radius: 5px;
}

.zoom-buttons {
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	bottom: 45px;
	width: 100%;
}

.tag_movil {
	border-radius: 15px;
	width: 5rem;
	text-align: center;
}

.milegajo-ant-modal {
	.ant-modal-body {
		padding: 0px;
	}

	.ant-modal-content {
		height: 100%;
	}
}

.milegajo-visor-pdf-header-separador {
	width: 1px;
	display: inline-block;
	height: 32px;
	background: rgba(var(--sk_foreground_low, 29, 28, 29), 0.13);
	margin: 0 8px;
}

.milegajo-visor-pdf-header-botones {
	margin-left: 10px;
}

.milegajo-visor-pdf-header {
	min-height: 64px;
	// border-bottom: 1px solid rgba(var(--sk_foreground_low, 29, 28, 29), 0.13);
	box-shadow: inset 0 1px 0 rgba(0, 0, 0, 0.2);
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 12px 16px;
	min-width: 0;

	.informacion {
		display: flex;
		align-items: center;

		.icono {
			font-size: 30px;
			color: #2b81de;
		}

		.texto {
			display: flex;
			flex-direction: column;
			margin-left: 5px;
			font-size: 12px;

			.periodo {
				color: black;
				font-weight: bold;
			}

			.documento {
				color: rgba(var(--sk_foreground_max, 29, 28, 29), 0.7);
				font-weight: bold;
			}
		}
	}

	.acciones {
		display: flex;

		.firmar {
			padding: 0 50px;
			margin-left: 15px;
			margin-right: 10px;
		}

		.consultar-rrhh {
			margin-left: 15px;
		}

		.cerrar {
			padding-left: 10px;
			padding-right: 10px;
		}
	}
}

.container-movil-pdf-header {
	height: 60px;
	max-height: 60px;
	display: flex;
	align-items: center;
	padding-left: 15px;
	color: #1c74b3;
	font-size: 15px;
	border-bottom: 1px solid #cccccc;
	font-weight: bold;
	box-shadow: 0px 2px 10px #ccc;
	width: 100%;
	z-index: 100;
	background-color: white;
}
.layout-content-mi-portal {
	// margin-top: 32px;
	padding: 20px 20px;
	// padding-bottom: 30px;
}
.layout-header-mi-portal {
	display: flex;
	position: fixed;
	width: 100%;
	top: 0;
	z-index: 100;
	background-color: #fff; // Fondo blanco para consistencia
	box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1), // Sombra suave
}

@layout-header-background: #FFFFFF;